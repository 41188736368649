import { useMemo } from 'react';
import { entitlementsPiletModals } from '@sharefiledev/entitlements-pilet';
import { usePiletApiContext } from '../../Contexts/PiletApiContext';
import { dummyEntitlementContentParams } from '../../data/dummyEntitlementContentParam';

type AppShellModalTypes = typeof entitlementsPiletModals.entitlementsModal;

export function useAppshellModal() {
	const { piletApi } = usePiletApiContext();

	const fns = useMemo(() => {
		const showAppShellModal = (modal: AppShellModalTypes, params?: any) => {
			piletApi.showModal(modal, params);
		};

		return {
			entitlementPaywall: (contentParams = dummyEntitlementContentParams) =>
				showAppShellModal(entitlementsPiletModals.entitlementsModal, contentParams),
		};
	}, [piletApi]);

	return fns;
}
