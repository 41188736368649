export const AWS_S3_BUCKET = {
	X_AMZ_SERVER_SIDE_ENCRYPTION: 'AES256',
	X_AMZ_ALGORITHM: 'AWS4-HMAC-SHA256',
	SUCCESS_ACTION_STATUS: 200,
};

export const SUPPORTED_FILE_EXTENSIONS = ['.doc', '.docx', '.txt', '.pdf'];
export const SUPPORTED_FILE_EXTENSIONS_BULK_SEND = ['.csv'];

export const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB

export const PAGE_WIDTH_THRESHOLD = 10;

export const MAX_CSV_FILE_SIZE = 5 * 1024 * 1024; // 5MB

export const SignerPageTabIndexes = {
	StartButton: 100,
	KeyboardShortcut: 102,
	HelpSection: 103,
	MoreActionsButton: 104,
	SubmitButton: 105,
	FirstField: 200,
	ControlsStart: 300,
	PageSelector: 301,
	NextPageButton: 302,
	ZoomOut: 303,
	ZoomIn: 304,
	SidePanelStart: 400,
	FieldsTab: 401,
	DetailsTab: 402,
	SidePanelFields: 500,
};

export const SignatureDrawerIndexes = {
	TypeTab: 901,
	DrawTab: 902,
	TypeInput: 921,
	TypeClearButton: 922,
	DrawCanvas: 925,
	DrawClearButton: 926,
	OkButton: 931,
	CancelButton: 932,
	CloseIcon: 941,
};

export const DOCGEN_ENTITLEMENT_CREATE_TEMPLATE =
	'sharefile:entitlementdef-create-doctemplate';
