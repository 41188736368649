import { getFeatureFlagValue } from '@citrite/feature-flags';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { FeatureFlag } from '../FeatureFlags/featureFlagDefinitions';
import { isSendForSignatureActionAvailable } from '../util/access';

export function canSendForSignature(app: PiletApi) {
	const { account, user } = app.sf.piletUserContext.get();

	return (
		getFeatureFlagValue(FeatureFlag.EnableEsignInFBP) &&
		isSendForSignatureActionAvailable(account, user)
	);
}

export const esignSupportedFileTypes = ['pdf', 'doc', 'docx', 'txt'];
