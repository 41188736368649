import axios, { AxiosRequestConfig } from 'axios';
import { AWS_S3_BUCKET } from '../../src/constants';
import { getLogger } from '../logger';
import { CreateDocumentParams } from './createDocument';
import { DocumentTemplateParams, DocumentUpdateParams } from './documentTemplate';
import {
	DocumentTransaction,
	DocumentTransactionUpdateParams,
} from './documentTransaction';
import { MergeTransactionUpdateParams } from './packager';
import {
	DeclineDocumentRequest,
	DocumentResponse,
	DocumentSigner,
	DocumentsResponse,
	DocumentStates,
	DocumentTransactionRequest,
	DocumentViewer,
	IAccountSettingsPayload,
	ICloneDocumentPayload,
	IMergeTransactionResponse,
	IPasscodePin,
	IReusableTemplateQueryParams,
	IReusableTemplateResponse,
	IUserSettingsPayload,
	PasscodeInformation,
	PasscodeValidationResult,
	PaymentPayload,
	RSAccountStatSetResponse,
	RSTokenHeaderTypes,
	SignatureResponse,
	SignaturesResponse,
	SignerPayment,
	SignerRequest,
	SignerSubmissionRequest,
	SignerSubmissionResponse,
} from './rsTypes';
import { getCachedToken, setCachedToken } from './tokenCache';

interface Configuration {
	baseUrl?: string;
	useBffAuthProxy?: boolean;
	getAccessToken?: () => Promise<string>;
	legacyUrl?: string;
}

const configuration: Configuration = {};

export function configureESignatureClient(config: Configuration) {
	const legacyUrl = config.baseUrl + '/legacy';
	config.legacyUrl = legacyUrl;
	Object.assign(configuration, config);
}

async function apiProxy<ResponseType>(
	requestConfig: AxiosRequestConfig,
	requestHeaders = {},
	isRetry = false
): Promise<ResponseType> {
	if (configuration.useBffAuthProxy) {
		requestConfig['headers'] = { 'X-BFF-CSRF': 'true', ...requestHeaders };
	} else {
		const token = await getToken({ useCache: true });

		requestConfig['headers'] = {
			Authorization: `Bearer ${token}`,
			...requestHeaders,
		};
	}

	getLogger().logInfo('API request config', {
		requestConfig: JSON.stringify(requestConfig),
	});
	const response = await axios.request<ResponseType>(requestConfig);
	if (response.status === 401 && !configuration.useBffAuthProxy && !isRetry) {
		await getToken({ useCache: false });
		return apiProxy<ResponseType>(requestConfig, true);
	} else if (response.status < 200 || response.status > 399) {
		getLogger().logError(new Error(JSON.stringify(response)), {
			customMessage: 'Error in API response',
		});
		throw new Error(JSON.stringify(response));
	}
	return response.data;
}

async function getToken({ useCache }: { useCache?: boolean }) {
	if (useCache) {
		const cachedToken = getCachedToken();
		if (cachedToken) {
			return cachedToken;
		}
	}

	const freshToken = await configuration.getAccessToken();
	setCachedToken(freshToken);
	return freshToken;
}

export const ESignatureClient = {
	async getShareFileItemMetadata(sharefileItemId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/sharefile/${sharefileItemId}/item`;
		const requestConfig: AxiosRequestConfig = { url: endpoint, method: 'get' };

		return apiProxy(requestConfig);
	},

	async getDocument(documentId: string, redirectToken?: string) {
		const endpoint = `${configuration.legacyUrl}/v1/documents/${documentId}`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'get',
		};

		const requestHeaders = redirectToken
			? {
					'Rightsignature-Redirect-Token': redirectToken,
			  }
			: null;

		return apiProxy<DocumentResponse>(requestConfig, requestHeaders);
	},

	async updateDocumentPin(documentId: string, newPasscode: string) {
		const endpoint = `${configuration.legacyUrl}/v1/documents/${documentId}/update_pin`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
			data: { pin: newPasscode },
		};

		return apiProxy<DocumentResponse>(requestConfig);
	},

	async createUploadFromSFItem(payload: {}) {
		const endpoint = `${configuration.legacyUrl}/v1/integrations/sharefile/documents/document`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: payload,
		};

		return apiProxy<any>(requestConfig);
	},

	async createUploadFromFBPItem(payload: {
		containerRID: string;
		file: {
			rid: string;
			name: string;
			sizeInBytes: number;
		};
	}) {
		const endpoint = `${configuration.baseUrl}/api/v1/signatureRequests/upload`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: payload,
		};

		return apiProxy<any>(requestConfig);
	},

	async createDocument(payload: DocumentTemplateParams) {
		const endpoint = `${configuration.legacyUrl}/v1/documents`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: payload,
		};

		return apiProxy<{ document: any; uploads: any[] }>(requestConfig);
	},

	async updateDocument(payload: DocumentUpdateParams, documentId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/documents/${documentId}`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
			data: payload,
		};

		return apiProxy<{ document: any; uploads: any[] }>(requestConfig);
	},

	async updateDocumentTags(payload: any, documentId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/documents/${documentId}/update_tags`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
			data: payload,
		};

		return apiProxy(requestConfig);
	},

	async deleteDocument(documentId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/documents/${documentId}`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'delete',
		};

		return apiProxy(requestConfig);
	},

	async cloneDocument(reusableTemplateId: string, payload: ICloneDocumentPayload) {
		const endpoint = `${configuration.legacyUrl}/v1/reusable_templates/${reusableTemplateId}/clone_document`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: payload,
		};

		return apiProxy<DocumentResponse>(requestConfig);
	},

	async createDocumentTransaction(payload: DocumentTransaction) {
		const endpoint = `${configuration.legacyUrl}/v1/document_transactions`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: payload,
		};

		return apiProxy<DocumentTransactionRequest>(requestConfig);
	},

	async updateDocumentTransaction(
		payload: DocumentTransactionUpdateParams,
		documentTransationId: string
	) {
		const endpoint = `${configuration.legacyUrl}/v1/document_transactions/${documentTransationId}`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
			data: payload,
		};

		return apiProxy<DocumentTransactionRequest>(requestConfig);
	},

	async editDocumentTransaction(documentTransationId: string, documentId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/document_transactions/${documentTransationId}/edit`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: { document_id: documentId },
		};

		return apiProxy<DocumentTransactionRequest>(requestConfig);
	},

	async getDocumentTransaction(documentTransationId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/document_transactions/${documentTransationId}`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'get',
		};

		return apiProxy<any>(requestConfig);
	},

	async getUpload(uploadId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/uploads/${uploadId}`;
		const requestConfig: AxiosRequestConfig = { url: endpoint, method: 'get' };

		return apiProxy<any>(requestConfig);
	},

	async deleteUpload(uploadId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/uploads/${uploadId}`;
		const requestConfig: AxiosRequestConfig = { url: endpoint, method: 'delete' };
		return apiProxy<any>(requestConfig);
	},

	async getRSAccount(sfAccountId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/accounts/${sfAccountId}`;
		const requestConfig: AxiosRequestConfig = { url: endpoint, method: 'get' };

		return apiProxy<any>(requestConfig);
	},

	async getRSAccountStats(sfAccountId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/accounts/${sfAccountId}/account_stat_set`;
		const requestConfig: AxiosRequestConfig = { url: endpoint, method: 'get' };

		return apiProxy<RSAccountStatSetResponse>(requestConfig);
	},

	async getRSUser(sfUserId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/users/${sfUserId}`;
		const requestConfig: AxiosRequestConfig = { url: endpoint, method: 'get' };

		return apiProxy<any>(requestConfig);
	},

	async getRSDocumentList(page = 1, pick = 20, search = '', state = '', isAdmin = false) {
		const endpoint = new URL(`${configuration.legacyUrl}/v1/documents`);

		if (!state) {
			state = Object.values(DocumentStates).join(',');
		}

		const params = {
			page: page,
			per_page: pick,
			search: search,
			state: state,
			account_documents: isAdmin,
		};

		Object.entries(params).forEach(([key, value]) => {
			if (value) {
				endpoint.searchParams.append(key, value.toString());
			}
		});
		const requestConfig: AxiosRequestConfig = { url: endpoint.toString(), method: 'get' };

		return apiProxy<DocumentsResponse>(requestConfig);
	},

	async createUpload(payload) {
		const endpoint = `${configuration.legacyUrl}/v1/uploads`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: payload,
		};

		return apiProxy<any>(requestConfig);
	},

	getS3Obj(upload) {
		return {
			key: upload.s3_upload.key,
			policy: upload.s3_upload.policy,
			'x-amz-credential': upload.s3_upload.credential,
			'x-amz-date': upload.s3_upload.date,
			'x-amz-signature': upload.s3_upload.signature,
			'x-amz-security-token': upload.s3_upload.security_token,
			s3BucketUrl: upload.s3_upload.bucket_url,
			success_action_status: AWS_S3_BUCKET.SUCCESS_ACTION_STATUS,
			'x-amz-server-side-encryption': AWS_S3_BUCKET.X_AMZ_SERVER_SIDE_ENCRYPTION,
			'x-amz-algorithm': AWS_S3_BUCKET.X_AMZ_ALGORITHM,
		};
	},

	async uploadFileToS3(presignedPostData, file) {
		const endpoint = presignedPostData.s3BucketUrl;
		const formData = new FormData();

		Object.keys(presignedPostData).forEach(key => {
			formData.append(key, presignedPostData[key]);
		});

		formData.append('file', file);
		// before submitting to s3 remove the bucket url key
		formData.delete('s3BucketUrl');

		const response = await fetch(endpoint, { method: 'POST', body: formData });
		return response;
	},

	async doneUploading(uploadId) {
		const endpoint = `${configuration.legacyUrl}/v1/uploads/${uploadId}/done_uploading.json`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
		};

		return apiProxy(requestConfig);
	},

	async createUploadFromLocalFile(file, userID) {
		const payload = {
			upload: {
				filename: file.name,
				size: file.size,
				content_type: file.type,
				user: userID,
			},
		};

		// GET S3 bucket URL
		const { upload } = await ESignatureClient.createUpload(payload);
		const s3Obj = ESignatureClient.getS3Obj(upload);

		// Upload file to S3
		const s3Res = await ESignatureClient.uploadFileToS3(s3Obj, file);

		if (s3Res.statusText === 'OK') {
			getLogger().logInfo('Uploaded file to S3');
			const doneRes = await ESignatureClient.doneUploading(upload.id);
			return doneRes;
		} else {
			getLogger().logError(new Error(JSON.stringify(s3Res)), {
				customMessage: 'Error in uploading file to S3',
			});
			throw new Error(JSON.stringify(s3Res));
		}
	},

	async saveSignature(payload: any) {
		const endpoint = `${configuration.legacyUrl}/v1/signatures`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'POST',
			data: payload,
		};

		return apiProxy(requestConfig);
	},

	async getSignature(
		id: string,
		documentId: string,
		token?: { token: string; tokenType: RSTokenHeaderTypes },
		signerId?: string
	) {
		const endpoint = `${configuration.legacyUrl}/v1/signatures/${id}`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'GET',
		};
		let requestHeaders = {
			'Document-Id': documentId,
			'Signer-Id': signerId,
		};

		if (!!token) {
			requestHeaders = {
				...requestHeaders,
				[token.tokenType]: token.token,
			};
		}

		return apiProxy<SignatureResponse>(requestConfig, requestHeaders);
	},

	async getUserSignatures(offset = 0, limit = 100) {
		const endpoint = new URL(`${configuration.legacyUrl}/v1/signatures`);

		const params = {
			offset: offset,
			limit: limit,
		};

		Object.entries(params).forEach(([key, value]) => {
			if (value) {
				endpoint.searchParams.append(key, value.toString());
			}
		});
		const requestConfig: AxiosRequestConfig = { url: endpoint.toString(), method: 'get' };

		return apiProxy<SignaturesResponse>(requestConfig);
	},

	async remindSigner(signerId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/signers/${signerId}/remind`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
		};

		return apiProxy(requestConfig);
	},

	async voidDocument(voidReason: string, documentId: string) {
		const payload = {
			void_reason: voidReason,
		};
		const endpoint = `${configuration.legacyUrl}/v1/documents/${documentId}/void`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: payload,
		};

		return apiProxy(requestConfig);
	},

	async forceCompleteDocument(documentId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/documents/${documentId}/force_complete`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
		};

		return apiProxy(requestConfig);
	},

	async extendExpiration(dueDate: Date, documentId: string) {
		const payload = {
			new_expiration: dueDate,
		};
		const endpoint = `${configuration.legacyUrl}/v1/documents/${documentId}/extend_expiration`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
			data: payload,
		};

		return apiProxy(requestConfig);
	},

	async modifySigner(signer: DocumentSigner, documentId: string) {
		const payload = {
			email: signer.email,
			name: signer.name,
		};
		const endpoint = `${configuration.legacyUrl}/v1/documents/${documentId}/signers/${signer.id}/modify_signer`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
			data: payload,
		};

		return apiProxy(requestConfig);
	},

	async createViewer(viewer: DocumentViewer, documentId: string) {
		const payload = {
			viewer: {
				email: viewer.email,
				name: viewer.name,
				document_id: documentId,
			},
		};

		const endpoint = `${configuration.legacyUrl}/v1/viewers`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: payload,
		};

		return apiProxy<DocumentViewer>(requestConfig);
	},

	async modifyViewer(viewer: DocumentViewer) {
		const payload = {
			email: viewer.email,
			name: viewer.name,
		};
		const endpoint = `${configuration.legacyUrl}/v1/viewers/${viewer.id}`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
			data: payload,
		};

		return apiProxy<DocumentViewer>(requestConfig);
	},

	async deleteViewer(viewerId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/viewers/${viewerId}`;
		const requestConfig: AxiosRequestConfig = { url: endpoint, method: 'delete' };
		return apiProxy<any>(requestConfig);
	},

	async unlockDocumentPasscode(documentId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/documents/${documentId}/reset_passcode_attempts`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'delete',
		};

		return apiProxy(requestConfig);
	},

	async unlockDocumentKba(documentId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/documents/${documentId}/unlock_kba`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
		};

		return apiProxy(requestConfig);
	},

	async getDocumentPasscode(documentId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/documents/${documentId}/pin`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'get',
		};

		return apiProxy<IPasscodePin>(requestConfig);
	},

	async updateAccountSettings(payload: IAccountSettingsPayload) {
		const endpoint = `${configuration.legacyUrl}/v1/account/settings`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
			data: { settings: { ...payload } },
		};

		return apiProxy<DocumentTransactionRequest>(requestConfig);
	},

	async updateUserSettings(payload: IUserSettingsPayload, userID: string) {
		const endpoint = `${configuration.legacyUrl}/v1/users/${userID}/settings`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
			data: { settings: { ...payload } },
		};

		return apiProxy<DocumentTransactionRequest>(requestConfig);
	},

	async getReusableTemplates(accountId: string, params: IReusableTemplateQueryParams) {
		const endpoint = new URL(`${configuration.legacyUrl}/v1/reusable_templates`);
		endpoint.searchParams.append('accountId', accountId);
		Object.entries(params).forEach(([key, value]) => {
			if (value !== '') {
				endpoint.searchParams.append(key, value.toString());
			}
		});
		const requestConfig: AxiosRequestConfig = { url: endpoint.toString(), method: 'get' };

		return apiProxy<IReusableTemplateResponse>(requestConfig);
	},

	async getOrCreateMergeTransaction(documentTransactionId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/document_transactions/${documentTransactionId}/merge_transaction`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
		};

		return apiProxy<IMergeTransactionResponse>(requestConfig);
	},

	async updateMergeTransaction(
		payload: MergeTransactionUpdateParams,
		mergeTransactionId: string
	) {
		const endpoint = `${configuration.legacyUrl}/v1/merge_transactions/${mergeTransactionId}`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
			data: payload,
		};

		return apiProxy<IMergeTransactionResponse>(requestConfig);
	},

	async createMergeUpload(mergeTransactionId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/merge_transactions/${mergeTransactionId}/merge_upload`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
		};

		return apiProxy<IMergeTransactionResponse>(requestConfig);
	},

	async getMergeTransaction(mergeTransactionId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/merge_transactions/${mergeTransactionId}`;
		const requestConfig: AxiosRequestConfig = { url: endpoint, method: 'get' };

		return apiProxy<IMergeTransactionResponse>(requestConfig);
	},

	async uploadSignersCsv(documentTransactionId: string, csvFile: File) {
		const formData = new FormData();
		formData.append('csv_file', csvFile);
		const endpoint = `${configuration.legacyUrl}/v1/document_transactions/${documentTransactionId}/signer_csv_upload`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: formData,
		};
		return apiProxy<DocumentTransactionRequest>(requestConfig);
	},

	async createDocumentFromResource(payload: CreateDocumentParams) {
		const endpoint = `${configuration.legacyUrl}/v1/document_transactions/create_document_from_resource`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: payload,
		};

		return apiProxy<DocumentTransactionRequest>(requestConfig);
	},

	async getSigner(signerId: string, queryParams: URLSearchParams) {
		const endpoint = `${configuration.legacyUrl}/v1/signers/${signerId}`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'get',
			params: queryParams,
		};

		return apiProxy<SignerRequest>(requestConfig);
	},

	async submitSignerResponse(
		signerId: string,
		queryParams: URLSearchParams,
		payload: SignerSubmissionRequest
	) {
		const endpoint = `${configuration.legacyUrl}/v1/signers/${signerId}`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'put',
			params: queryParams,
			data: payload,
		};

		return apiProxy<SignerSubmissionResponse>(requestConfig);
	},

	async uploadComponentAttachment(
		signerId: string,
		componentId: string,
		queryParams: URLSearchParams,
		attachment: File,
		updateUploadProgress?,
		controller?: AbortController
	) {
		const formData = new FormData();
		formData.append('attachment', attachment);
		const endpoint = `${configuration.legacyUrl}/v1/signers/${signerId}/components/${componentId}/attachments`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			params: queryParams,
			data: formData,
			signal: controller.signal,
			onUploadProgress: event => {
				updateUploadProgress(event.progress);
			},
		};

		return apiProxy<SignerSubmissionResponse>(requestConfig);
	},

	async signerPaymentRequest(
		signerId: string,
		componentId: string,
		queryParams: URLSearchParams,
		payload: PaymentPayload
	) {
		const endpoint = `${configuration.legacyUrl}/v1/signers/${signerId}/components/${componentId}/signer_payments`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			params: queryParams,
			data: payload,
		};

		return apiProxy<{ signer_payment: SignerPayment }>(requestConfig);
	},

	async declineDocument(signerId: string, payload: DeclineDocumentRequest) {
		const endpoint = `${configuration.legacyUrl}/v1/signers/${signerId}/decline`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: payload,
		};

		return apiProxy<SignerRequest>(requestConfig);
	},

	async getPasscodeInformation(signerId: string, queryParams: URLSearchParams) {
		const endpoint = `${configuration.legacyUrl}/v1/signers/${signerId}/passcode`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'get',
			params: queryParams,
		};

		return apiProxy<PasscodeInformation>(requestConfig);
	},

	async checkPasscode(signerId: string, queryParams: URLSearchParams) {
		const endpoint = `${configuration.legacyUrl}/v1/signers/${signerId}/check_passcode`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			params: queryParams,
		};

		return apiProxy<PasscodeValidationResult>(requestConfig);
	},

	async sendNewViewLink(signerId: string, redirectToken: string) {
		const formData = new FormData();

		if (signerId) {
			formData.append('signer_id', signerId);
		}

		if (redirectToken) {
			formData.append('token', redirectToken);
		}

		const endpoint = `${configuration.legacyUrl}/v1/redirect_tokens`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
			data: formData,
		};

		return apiProxy(requestConfig);
	},

	async sendIdentityVerification(signerId: string) {
		const endpoint = `${configuration.legacyUrl}/v1/signers/${signerId}/resend_identity_verification`;
		const requestConfig: AxiosRequestConfig = {
			url: endpoint,
			method: 'post',
		};

		return apiProxy<SignerRequest>(requestConfig);
	},

	async authorizeWithDigiCert(
		providerId: string,
		signerId: string,
		identityToken: string,
		subdomain: string
	) {
		const endpoint = new URL(`${configuration.baseUrl}/api/v1/eIDASProvider/authorize`);
		endpoint.searchParams.append('providerId', providerId);
		endpoint.searchParams.append('signerId', signerId);
		endpoint.searchParams.append('identityToken', identityToken);
		endpoint.searchParams.append('subdomain', subdomain);
		const requestConfig: AxiosRequestConfig = {
			url: endpoint.toString(),
			method: 'get',
		};

		return apiProxy<string>(requestConfig);
	},

	async getDigiCertCredentials(providerId: string, identityToken: string) {
		const endpoint = new URL(
			`${configuration.baseUrl}/api/v1/eIDASProvider/credentials/list`
		);
		endpoint.searchParams.append('providerId', providerId);
		endpoint.searchParams.append('identityToken', identityToken);
		const requestConfig: AxiosRequestConfig = {
			url: endpoint.toString(),
			method: 'get',
		};

		return new Promise<Array<string>>((resolve, reject) => {
			apiProxy<Array<string>>(requestConfig)
				.then(response => resolve(response))
				.catch(err => reject(err.response.status));
		});
	},

	async authorizeCredential(
		providerId: string,
		identityToken: string,
		credentialId: string,
		documentHash: string
	) {
		const endpoint = new URL(
			`${configuration.baseUrl}/api/v1/eIDASProvider/credentials/authorize`
		);
		endpoint.searchParams.append('providerId', providerId);
		endpoint.searchParams.append('identityToken', identityToken);
		const requestConfig: AxiosRequestConfig = {
			url: endpoint.toString(),
			method: 'post',
			data: {
				credentialId: credentialId,
				hash: documentHash,
			},
		};

		return new Promise<string>((resolve, reject) => {
			apiProxy<string>(requestConfig)
				.then(response => resolve(response))
				.catch(err => reject(err.response.status));
		});
	},

	async getEidasSignature(
		providerId: string,
		identityToken: string,
		credentialId: string,
		documentHash: string,
		sad: string
	) {
		const endpoint = new URL(`${configuration.baseUrl}/api/v1/eIDASProvider/sign`);
		endpoint.searchParams.append('providerId', providerId);
		endpoint.searchParams.append('identityToken', identityToken);
		const requestConfig: AxiosRequestConfig = {
			url: endpoint.toString(),
			method: 'post',
			data: {
				credentialId: credentialId,
				hash: documentHash,
				SAD: sad,
			},
		};

		return new Promise<string>((resolve, reject) => {
			apiProxy<string>(requestConfig)
				.then(response => resolve(response))
				.catch(err => reject(err.response.status));
		});
	},
};
