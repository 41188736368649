import { type EntitlementsContentParams } from '@sharefiledev/entitlements-pilet';

export const dummyEntitlementContentParams: EntitlementsContentParams = {
	entitlementDefRID: 'sharefile:entitlementdef-send-for-signature',
	entitlementRID: 'sharefile:productplan-sfadv001/entitlement-send-for-signature',
	entitled: true,
	evaluationResult: {
		isPaywallEnabled: true,
		showLimitBanner: true,
		limit: '5',
		current: '5',
		requested: '1',
		remaining: '0',
	},
	httpStatusCodeResponseToCaller: 402,
};
