import { IAccountStatSetContext } from '../Contexts/AccountStatSetContext';
import { ESignatureClient } from '../data/eSignatureClient';

export function getStatsFromAccountId(
	accountId: string
): Promise<IAccountStatSetContext> {
	return ESignatureClient.getRSAccountStats(accountId).then(response => {
		const accountStatSetInstance: IAccountStatSetContext = {
			accountId: response.account_stat_set.id,
			canSendDocument: response.account_stat_set.can_send_document,
			canCreateTemplate: response.account_stat_set.can_create_template,
			documentLimit: response.account_stat_set.document_limit,
			documentsSent: response.account_stat_set.documents_sent,
			seatsUsed: response.account_stat_set.seats_used,
			templateLimit: response.account_stat_set.template_limit,
			templateLimitReached: response.account_stat_set.template_limit_reached,
			totalSeats: response.account_stat_set.total_seats,
		};

		return accountStatSetInstance;
	});
}
