import { getFeatureFlagValue } from '@citrite/feature-flags';
import {
	Account,
	AccountPreferences,
	IntegrationProvider,
	User,
	UserRole,
} from '@citrite/sf-api';
import { FeatureFlag } from '../FeatureFlags/featureFlagDefinitions';

export const isAccountStorageLimitReached = (account: Account) => {
	return (
		getFeatureFlagValue(FeatureFlag.EnableStorageEnforcement) &&
		account.DiskSpace?.EnforceStorageUsage
	);
};

const canSendForSignature = (account: Account, user: User) => {
	return (
		eSignatureEnabledForAccount(account.Preferences) &&
		user.Roles.includes(UserRole.CanSendDocumentsForSignature)
	);
};

const eSignatureEnabledForAccount = (accountPrefs: AccountPreferences) => {
	return (
		!getFeatureFlagValue(FeatureFlag.DisableRightSignature) &&
		accountPrefs.EnableIntegrations &&
		accountPrefs.IntegrationProviders.includes(IntegrationProvider.RightSignature4)
	);
};

export const isSendForSignatureActionAvailable = (account: Account, user: User) => {
	return (
		((canSendForSignature(account, user) &&
			getFeatureFlagValue(FeatureFlag.EnableEsignPilet)) ||
			isProductLedGrowthEnabled(account)) &&
		!user.Roles.includes(UserRole.Client)
	);
};

export const isSignerRouteEnabled = () => {
	return getFeatureFlagValue(FeatureFlag.EnableEsignSignerRoute);
};

export const isProductLedGrowthEnabled = (account: Account) => {
	if (account.PlanName !== 'ShareFile Advanced' || account.BillingType !== 'SFHybris') {
		return false;
	}

	return (
		getFeatureFlagValue(FeatureFlag.EnablePlgForEsign) &&
		getFeatureFlagValue(FeatureFlag.EnableProductLedGrowth) &&
		getFeatureFlagValue(FeatureFlag.EnableEntitlementsPilet)
	);
};

export const isAccountCreatedBefore30May2024 = (account: Account): boolean =>
	new Date(account.CreationDate) < new Date('2024-05-30');
