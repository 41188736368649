import * as React from 'react';
import { virusBlocksActions } from '@citrite/citrix-files-ui';
import { Account, File, isFile, Item, ItemOperations, User } from '@citrite/sf-api';
import { notifyError, SendForSignatureActionIcon } from '@sharefiledev/flow-web';
import type {
	ExtensionParams,
	FolderItemContextActionAvailableParams,
	PiletApi,
} from '@sharefiledev/sharefile-appshell';
import { useHistory } from 'react-router-dom';
import { useAppshellModal } from './Common/Hooks/useAppshellModal';
import { PiletApiContextProvider } from './Contexts/PiletApiContext';
import { WorkflowTypes } from './data/rsTypes';
import { newDocumentPath, signatureRequestItemPath } from './routes';
import { t } from './util';
import {
	isAccountStorageLimitReached,
	isProductLedGrowthEnabled,
	isSendForSignatureActionAvailable,
} from './util/access';
import { getStatsFromAccountId } from './util/account_stats';

export interface SignatureProps {
	selectedItem: any;
	ActionButton: any;
}

export interface PiletUserContext {
	user: User;
	account: Account;
}

interface SendForSignatureProps extends SignatureProps {
	actionButton?: Function;
	extensionName: string;
	redirectPath: string;
	userContext: PiletUserContext;
}

export function SendForSignature(params: SendForSignatureProps) {
	const handleClick = useSendForSignatureClick({
		selectedItem: params.selectedItem,
		userContext: params.userContext,
		workflow: WorkflowTypes.SignatureRequest,
	});

	const { user, account } = params.userContext;

	let ActionButton;

	if (params.extensionName === 'urn:sfextslot:sharefile-contentviewer:sendforsignature') {
		const actionButtonProps = {
			label: t('esign-pilet-ui:sendForSignature'),
			icon: SendForSignatureActionIcon,
			onClick: handleClick,
		};
		ActionButton = () => <params.ActionButton {...actionButtonProps} />;
	} else if (
		params.extensionName === 'urn:sfextslot:sharefile-appshell:dashboard:shortcuts'
	) {
		const actionButtonProps = {
			onClick: handleClick,
			icon: SendForSignatureActionIcon,
		};
		ActionButton = () => {
			return isSendForSignatureActionAvailable(account, user) &&
				!isAccountStorageLimitReached(account) ? (
				<params.ActionButton {...actionButtonProps}>
					{t('esign-pilet-ui:sendForSignature')}
				</params.ActionButton>
			) : (
				<></>
			);
		};
	}

	return <ActionButton />;
}

export interface FileListContextActionProps
	extends ExtensionParams<'urn:sfextslot:sharefile-appshell:folder:item-context-actions'> {
	ActionComponent: any;
	userContext: PiletUserContext;
}

export function SendForSignatureFileListContextAction(props: FileListContextActionProps) {
	const onClick = useSendForSignatureClick({
		selectedItem: props.selectedItems[0],
		userContext: props.userContext,
		workflow: WorkflowTypes.SignatureRequest,
	});

	return (
		<props.ActionComponent icon={SendForSignatureActionIcon} onClick={onClick}>
			{t('esign-pilet-ui:sendForSignature')}
		</props.ActionComponent>
	);
}

export function SignYourselfFileListContextAction(props: FileListContextActionProps) {
	const onClick = useSendForSignatureClick({
		selectedItem: props.selectedItems[0],
		userContext: props.userContext,
		workflow: WorkflowTypes.SelfSign,
	});

	return (
		<props.ActionComponent onClick={onClick}>
			{t('esign-pilet-ui:signYourself')}
		</props.ActionComponent>
	);
}

export interface SignatureDetailsFileListItemProps {
	ActionButton: any;
	documentId: string;
}

export function SignatureDetailsFileListItem(props: SignatureDetailsFileListItemProps) {
	const history = useHistory();
	const handleClick = () => {
		const redirectPath = window.location.pathname;
		history.push({
			pathname: signatureRequestItemPath.replace(':documentId', props.documentId),
			state: {
				redirectPath,
			},
		});
	};

	return (
		<props.ActionButton onClick={handleClick}>
			{t('esign-pilet-ui:view')}
		</props.ActionButton>
	);
}

export interface ContentViewerActionProps extends Omit<SignatureProps, 'ActionButton'> {
	ActionComponent: any;
	userContext: PiletUserContext;
}

export function SendForSignatureContentViewerContextAction(
	props: ContentViewerActionProps
) {
	const onClick = useSendForSignatureClick({
		selectedItem: props.selectedItem,
		userContext: props.userContext,
		workflow: WorkflowTypes.SignatureRequest,
	});

	return (
		<props.ActionComponent icon={SendForSignatureActionIcon} onClick={onClick}>
			{t('esign-pilet-ui:sendForSignature')}
		</props.ActionComponent>
	);
}

export function SignYourselfContentViewerContextAction(props: ContentViewerActionProps) {
	const onClick = useSendForSignatureClick({
		selectedItem: props.selectedItem,
		userContext: props.userContext,
		workflow: WorkflowTypes.SelfSign,
	});

	return (
		<props.ActionComponent icon={SendForSignatureActionIcon} onClick={onClick}>
			{t('esign-pilet-ui:signYourself')}
		</props.ActionComponent>
	);
}

function isAvailable(
	{ selectedItems, managingUserContent }: FolderItemContextActionAvailableParams,
	account: Account,
	user: User
) {
	if (isAccountStorageLimitReached(account)) {
		return false;
	}
	if (!managingUserContent && selectedItems.length === 1) {
		const [item] = selectedItems;
		return (
			isFile(item) &&
			!(item as File).FileLockInfo &&
			canSignRS4(item, account) &&
			!virusBlocksActions(item, account.Preferences) &&
			isSendForSignatureActionAvailable(account, user)
		);
	}

	return false;
}

function canSignRS4(item: Item, account: Account): boolean {
	// The "ItemOperations" property on the item object varies for "sf advance account", as it's determined by factors such as account capabilities, account settings, and file lock status.
	// If the "EnablePlgForEsign", "EnableProductLedGrowth" & "EnableEntitlementsPilet" feature flag is enabled, we assume it's "sf advance account" and we need to manually check the file extension.
	if (isProductLedGrowthEnabled(account)) {
		return isValidItem(item);
	}
	return Boolean(item.ItemOperations & ItemOperations.CanSignRS4);
}

function isValidItem(item: Item): boolean {
	const validExtensions = new Set(['doc', 'docx', 'pdf', 'txt', 'rtf']);
	const fileExtension = item?.FileName?.split('.').pop()?.toLowerCase();
	return fileExtension ? validExtensions.has(fileExtension) : false;
}

SendForSignatureFileListContextAction.isAvailable = isAvailable;
SignYourselfFileListContextAction.isAvailable = isAvailable;

export function useSendForSignatureClick(params: {
	selectedItem: Item;
	userContext: PiletUserContext;
	workflow: string;
	containerRID?: string;
}) {
	const history = useHistory();
	const appShellModal = useAppshellModal();
	const redirectPath = window.location.pathname + window.location.search;
	const maxSignaturePdfSize = 20 * 1024 * 1024;
	const selectedItemSize = params.selectedItem?.FileSizeBytes;
	const selectedFilename = params.selectedItem?.FileName;
	return function handleClick() {
		if (selectedItemSize >= maxSignaturePdfSize) {
			notifyError(
				<>
					<b>
						{t('esign-pilet-ui:sizeLimitFile', {
							filename: selectedFilename,
						})}
					</b>
					{t('esign-pilet-ui:sizeLimitMessage')}
				</>
			);
			return;
		}

		// remove key entitlements from userContext
		const updatedUserContext = {
			user: params.userContext.user,
			account: params.userContext.account,
		};

		const accountStats = getStatsFromAccountId(updatedUserContext.account.Id);

		accountStats
			.then(accountStatSetInstance => {
				if (
					!accountStatSetInstance.canSendDocument &&
					params.workflow === WorkflowTypes.SignatureRequest &&
					isProductLedGrowthEnabled(updatedUserContext.account)
				) {
					appShellModal.entitlementPaywall();
					return;
				}
				history.push({
					pathname: newDocumentPath,
					state: {
						data: {
							selectedItem: params.selectedItem,
							userContext: updatedUserContext,
							workflow:
								!accountStatSetInstance.canSendDocument &&
								params.workflow === WorkflowTypes.SignatureRequest
									? WorkflowTypes.SelfSign
									: params.workflow,
							accountStatSet: accountStatSetInstance,
							containerRID: params?.containerRID,
						},
						redirectPath,
					},
				});
			})
			.catch(() => {
				notifyError(t('esign-pilet-ui:accountStatsFetchErrorMessage'));
				return;
			});
	};
}

export function RegisterSendForSignature(app: PiletApi, extensionName: string) {
	app.registerExtension(extensionName, props => {
		const { params } = props;
		const redirectPath = window.location.pathname;
		const userContext = app.sf.piletUserContext.get();
		return (
			<PiletApiContextProvider piletApi={app}>
				<SendForSignature
					{...params}
					extensionName={extensionName}
					redirectPath={redirectPath}
					userContext={userContext}
				/>
			</PiletApiContextProvider>
		);
	});
}
